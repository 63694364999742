import React from "react";
import "@glidejs/glide/dist/css/glide.core.min.css";
import GlideComponent from './GlideComponent'
import Image from "next/legacy/image";
import LeftIcon from "../../icons/arrow/ic-left-48.svg";
import RightIcon from "../../icons/arrow/ic-right-48.svg";
import styles from './slider.module.scss'

export default class GlideOnlyArrowComponent extends GlideComponent {
  render() {
    const {children, settings} = this.props;
    return (
      <div>
        <div className="glide" ref={node => this.carousel = node}>
          <div data-glide-el="track" className="glide__track">
            <div className="glide__slides">
              {children}
            </div>
          </div>
          {
            !settings.hideNav &&  (
              <div className={"glide__arrows " + styles.slider__nav} data-glide-el="controls">
                <button className={"glide__arrow glide__arrow--left btn btn-link " + styles.left__arrow} data-glide-dir="<">
                  <Image src={LeftIcon} alt="left"/>
                </button>
                <button className={"glide__arrow glide__arrow--right btn btn-link " + styles.right__arrow} data-glide-dir=">">
                  <Image src={RightIcon} alt="right"/>
                </button>
              </div>
            )
          }
        </div>
      </div>
    )
  }
}
