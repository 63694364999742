import React from 'react';
import Image from "next/legacy/image";

import {joinClassNames} from "../../../helpers/Utils";
import planLevel, {planLevelData} from "../../../const/planLevel";

export const dataLevel = [
    {id: planLevel.bronze, ...planLevelData[planLevel.bronze]},
    {id: planLevel.silver, ...planLevelData[planLevel.silver]},
    {id: planLevel.gold, ...planLevelData[planLevel.gold]},
    {id: planLevel.platinum, ...planLevelData[planLevel.platinum]},
];

const CoachingPlanLevel = ({levelId, width = 18, height = 18, backColor}) => {
    return (
        <>
            <Image
                src={planLevelData[levelId]?.src}
                loading={"eager"}
                alt={planLevelData[levelId]?.label}
                width={width}
                height={height}
            />
            <span className={joinClassNames("label__level ml-2", backColor && "label__level-with-bc")}>
                {planLevelData[levelId]?.label}
            </span>
        </>
    );
};

export default CoachingPlanLevel;