import React from 'react';
import Colxx from "../Colxx";
import Link from "next/link";
import Image from "next/legacy/image";
import {Row} from "reactstrap";

const InfoSection = ({label, text, img, classNameSection, classNameBtn, linkText, link}) => {
    return (
        <Row className="info-section">
            <Colxx lg="7" className={classNameSection}>
                <h2>{label}</h2>
                <p>{text}</p>
                {link && <div className={classNameBtn}><Link href={link}>{linkText}</Link></div>}
            </Colxx>
            <Colxx lg="5" className="product-icon">
                <Image src={img} alt="logo"/>
            </Colxx>
        </Row>
    );
};

export default InfoSection;