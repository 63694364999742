export const getDirection = () => {
    let direction = 'ltr';
    if (localStorage.getItem("direction")) {
        const localValue = localStorage.getItem("direction");
        if (localValue === "rtl" || localValue === "ltr") {
            direction = localValue;
        }
    }
    return {
        direction,
        isRtl: direction === "rtl",
    };
};