export const GLIDE_SETTINGS = {
    gap: 30,
    perView: 5,
    type: "carousel",
    breakpoints: {
        576: {
            perView: 1
        },
        992: {
            perView: 2,
        },
        1200: {
            perView: 3,
        },
        1440: {
            perView: 4
        },
    }
};