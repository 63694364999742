import bronze from "../icons/landing/icons/ic-bronze-64-px.svg";
import silver from "../icons/landing/icons/ic-silver-64-px.svg";
import gold from "../icons/landing/icons/ic-gold-64-px.svg";
import platinum from "../icons/landing/icons/ic-platinum-64-px.svg";

const planLevel = {
    bronze: 1,
    silver: 2,
    gold: 3,
    platinum: 4,
}

export default planLevel

export const planLevelData = {
    [planLevel.bronze]: {src: bronze, label: "Bronze"},
    [planLevel.silver]: {src: silver, label: "Silver"},
    [planLevel.gold]: {src: gold, label: "Gold"},
    [planLevel.platinum]:{src: platinum, label: "Platinum"}
}
