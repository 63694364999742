import React from 'react';
import Image from "next/legacy/image";

import beginnerIcon from "../../../icons/landing/icons/ic-level-1.svg"
import intermediateIcon from "../../../icons/landing/icons/ic-level-2.svg";
import advancedIcon from "../../../icons/landing/icons/ic-level-3.svg";

const LEVELS_DATE = [
    {icon: beginnerIcon, label: 'Beginner'},
    {icon: intermediateIcon, label: 'Intermediate'},
    {icon: advancedIcon, label: 'Advanced'}
];

const Level = ({level}) => {
    if (level <= 0 || level === undefined) return null;
    const Icon = LEVELS_DATE[level - 1].icon;
    return (
        <div className="level__workout">
            <span className="img">
                <Image
                    loading={"eager"}
                    alt={LEVELS_DATE[level - 1].label}
                    src={Icon}
                    width={18}
                    height={18}
                />
            </span>
            <span className="text"> {LEVELS_DATE[level - 1].label} </span>
        </div>
    );
};

export default Level;