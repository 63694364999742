import React from 'react';
import{formatPrice, joinClassNames} from '../../helpers/Utils';
import styles from './price.module.scss'

const Price = ({price, oldPrice, className, isProgram, classNameMonth = styles.month}) => (
    <div className={joinClassNames(styles.card__price, className, !price && styles.free)}>
        <span>
          {price ? `$${formatPrice(price)}` : 'Free'}
            {price && !isProgram ? <span className={classNameMonth}>/mo</span> : null}
        </span>
        {!!oldPrice &&
            <span className={styles.old}>(Reg:&nbsp;
                <span className={styles.strike}>${formatPrice(oldPrice)})</span>
            </span>
        }
    </div>
);

export default Price;