import React from 'react';
import {getProgramFinalPrice, userName} from './helpers/index'
import Level from "./Level";
import Equipments from "./Equipments";
import Rating from "../../Rating";
import Image from "next/legacy/image";
import Price from "../../Price/Price";
import duration from '../../../icons/landing/icons/ic-duration.svg'
import blur from "../../../public/883.jpg"

import styles from './workoutPrograms.module.scss'
import base from "../../../icons/landing/baseProgram.jpg";
import {blurBase64} from "../../../const/icons";
import {joinClassNames} from "../../../helpers/Utils";

const ProgramsCard = ({product, isDisplayAuthor = true}) => {
    const price = getProgramFinalPrice(product);
    let placeholder = "blur";

    const loading = () => placeholder="empty";

    return (
        <article className={styles.card + " " + styles.l__product__card}>
            <Image
                src={product?.picture?.resizedLink || product?.picture?.link || base}
                blurDataURL={blurBase64}
                placeholder={"empty"}
                onLoadingComplete={loading}
                alt={product.title}
                loading={"eager"}
                height={476}
                width={476}
                className={styles.img__card}
            />

            <div className={styles.content}>
                <h3 className={styles.title}>{product.title}</h3>
                {isDisplayAuthor && <p className={joinClassNames(styles.author, "text-truncate text-gray")}>
                    {`By ${userName(product.author)}`}
                </p>}

                <div className={styles.workout__level}>
                    <div>
                        <Level level={product.level}/>
                    </div>
                    <div>
                        <Equipments equipments={product.equipments || []} id={product.id}/>
                    </div>
                </div>

                <Rating rate={product.rating}/>

                <div className={styles.duration}>
                    <Image
                        loading={"eager"}
                        src={duration}
                        alt={'duration'}
                        width={18}
                        height={18}
                    />
                    <span className={styles.days}>{product.duration}</span>
                    <span className="text-gray">Days</span>
                </div>

                <Price
                    price={price}
                    oldPrice={product.regularPrice !== price && product.regularPrice}
                    isProgram={true}
                />
            </div>
        </article>
    );
};

export default ProgramsCard;