import React from 'react';
import Image from "next/legacy/image";
import barbelIcon from "../../../icons/landing/icons/ic-barbel.svg";
import {PopoverBody, UncontrolledPopover} from 'reactstrap'
import PerfectScrollbar from "react-perfect-scrollbar";
import {sortByTextField} from "../../../helpers/Utils";

const Equipments = ({equipments, id}) => {
    let equipmentPart = null;
    if (equipments.length === 0) {
        equipmentPart = (<span>No Equipment</span>);
    }
    if (equipments.length === 1) {
        equipmentPart = (<span>{ equipments[0].name }</span>);
    }
    if (equipments.length > 1){
        equipmentPart = (
            <>
                <span id={ "pp-" + id } className="text-truncate">{ equipments.length } Equipments</span>

                <UncontrolledPopover trigger="hover"
                                     placement="bottom"
                                     target={ "pp-" + id }
                                     className="popover-primary workout-program-card-popover">
                    <PopoverBody className="top-0">
                        <div className="h-100">
                            <PerfectScrollbar
                                options={ { suppressScrollX: true, wheelPropagation: false } }>
                                { sortByTextField(equipments, "name").reduce((acc, val) => [...acc, val.name], []).join(" | ") }
                            </PerfectScrollbar>
                        </div>
                    </PopoverBody>
                </UncontrolledPopover>
            </>
        );
    }
    return (
        <>
            <Image
                src={barbelIcon}
                loading={"eager"}
                alt={'equip'}
                width={18}
                height={12}
            />
            <span className={"ml-1"}>{equipmentPart}</span>
        </>
    );
};

export default Equipments;