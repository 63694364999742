import React from 'react';
import {Container, Row} from "reactstrap";
import Colxx from "../../Colxx";

import workoutLogo from '../../../icons/landing/ic-workout.svg'
import styles from './workoutPrograms.module.scss'

import GlideOnlyArrowComponent from "../../carousel/GlideOnlyArrowComponent";
import {GLIDE_SETTINGS} from "../../../const/glide";
import ProgramsCard from "./ProgramsCard";
import InfoSection from "../InfoSection";
import Link from "next/link";
import {WORKOUTS} from "../../../const/links";

const WorkoutPrograms = (
    {
        workouts,
        author = 'Featured',
        link={pathname: WORKOUTS},
        isDisplayAuthor = true,
    }) => {

    const Wrapper = workouts.length > 5 ? GlideOnlyArrowComponent
        : ({children}) => <div className='d-flex justify-content-center'>
            {children}
        </div>

    return (
        <section className="products-block">
            <Container>
                <InfoSection
                    label={'Find a workout program that suits your needs'}
                    classNameSection={styles.product__info}
                    classNameBtn={styles.btn_pink}
                    img={workoutLogo}
                    text={`Maecenas pulvinar, nunc in gravida lobortis, dui lacus maxi
                            mus ex, eget convallis felis erat at purus. Cras id ante efficit
                            ur, fringilla metus sed, blandit nunc.`}
                    linkText={'Browse Programs'}
                    link={link}
                />
                <div className="featured-text">
                    {author} Workout Programs
                </div>
                <Row className="m-t">
                     <Colxx>
                        <Wrapper settings={GLIDE_SETTINGS}>
                            {workouts.map(program => (
                                (<Link
                                    href={WORKOUTS + '/' + program.id}
                                    key={program.id}
                                    className={styles.link}
                                >
                                    <ProgramsCard product={program} isDisplayAuthor={isDisplayAuthor}/>
                                </Link>)
                            ))}
                        </Wrapper>
                    </Colxx>
                </Row>
            </Container>
        </section>
    );
};

export default WorkoutPrograms;